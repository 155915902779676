import React, { useState } from "react";
import inviteTab from "../../../../asset/home/employees_survey/invite-tablet.png";
import inviteDesktop from "../../../../asset/home/employees_survey/invite-desktop.png";
import employee from "../../../../asset/home/employees_survey/invite-mobile.png";
import { useNavigate } from "react-router-dom";

export default function EmployeesSurvey() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/employees-survey");
    scrollToTop();
  };
  return (
    <div
      onClick={handleClick}
      className=" md:relative p-0 m-0 h-[322px] md:h-[364px] w-full md:w-[510px] rounded-[24px] cursor-pointer overflow-hidden md:transition-shadow duration-300 ease-in-out md:hover:shadow-[0px_8px_24px_0px_rgba(0,0,0,0.08)]"
    >
      {/* Background Image */}
      <img
        src={inviteDesktop}
        alt="Proctoring Tab Background"
        className="absolute inset-0 w-full h-full  object-cover  hidden md:flex"
      />
      <img
        src={inviteTab}
        alt="Proctoring Tab Background"
        className="absolute inset-0 w-full h-full hidden object-cover sm:flex md:hidden rounded-3xl"
      />{" "}
      <img
        src={employee}
        alt="Proctoring Tab Background"
        className="absolute inset-0 w-full h-full  object-cover flex sm:hidden rounded-3xl"
      />
      {/* Overlay Content */}
      <div className="relative z-10 flex flex-col items-start justify-between h-full p-6">
        {/* Number */}
        <p className="font-satoshi font-900 text-lg text-[#CECECE] leading-[25px]">
          03
        </p>

        {/* Text Content */}
        <div className="w-full sm:max-w-[330px] md:max-w-[240px] xl:max-w-[240px]">
          <p className="text-2xl text-[#1E1B1E] font-satoshi font-700 leading-[33px] pb-2">
            Employees’ Survey
          </p>
          <p className="text-base font-satoshi font-medium text-[#626062] leading-[22px]">
            Empowering organizations to understand their workforce better.
            Providing actionable insights into employee satisfaction,
            engagement, and culture through detailed analysis of your custom
            questions. With privacy-first options like anonymous surveys, gather
            honest feedback effortlessly.
          </p>
        </div>
      </div>
    </div>
  );
}
