import React from "react";
import { GradientSection } from "../../ui/wrappers";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";

function CampusHiringMain({ gradient, color }) {
  return (
    <GradientSection
      nav={true}
      color={color}
      bgColor={color}
      gradient={gradient}
      textColor={"text-azure"}
    >
      <div className="flex flex-col relative">
        {/* Headings */}
        <h1 className="text-[24px] lg:text-[48px] font-krona tracking-[-0.05em]  max-w-[781px]">
          Revolutionize your campus hiring strategy with Ambition Hire{" "}
        </h1>

        <div className="text-[14px] lg:text-[22px] font-500 leading-[18.9px] md:leading-[34px] lg:leading-normal mt-4 lg:mt-6 text-[#505050]">
          <p className="mt-5">
            Streamline bulk recruitment, engage top-tier talent, and make
            data-driven decisions—all with ease and efficiency. Campus hiring is
            a critical opportunity for organizations to tap into fresh, dynamic
            talent pools. Ambition Hire’s Campus Hiring module simplifies the
            complexities of hiring at scale, allowing you to focus on
            identifying the best candidates while we handle the logistics.
          </p>
        </div>
        {/* Buttons */}
        <div className="mt-6 mb-12 lg:mb-24 lg:mt-[64px] flex flex-col   sm:flex-row gap-4 lg:gap-6">
          <Link
            to={"/quiz-demo"}
            className="flex gap-2 lg:gap-[18px] items-center justify-center bg-azure px-[32px] py-[12px] lg:py-4 rounded-[128px] w-full lg:w-auto"
          >
            <span className="text-[13px] lg:text-[18px] font-krona text-white">
              Schedule a Quick Demo
            </span>
            <p className="bg-white rounded-full grid place-content-center p-[5.455px] lg:p-[7.273px]">
              <FaArrowRight className={`text-[20px] text-azure`} />
            </p>
          </Link>
        </div>
      </div>
    </GradientSection>
  );
}

export default CampusHiringMain;
