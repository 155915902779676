import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QuestionDetails from "./components/questionLibrary/questionDetails";
import {
  ResumeParserPage,
  ProctoringPage,
  OnlineAssessmentPage,
  AboutPage,
  VideoInterviewPage,
  HomePage,
  TermsAndConditions,
  GDPRPolicy,
  PrivacyPolicy,
  QuestionLibraryPage,
  ThanksPage,
  FrequentlyAskedQuestionPage,
  FreeTrialPage,
  PricingPage,
  ContactPage,
  DemoQuizPage,
  BlogPage,
  TNI,
  ResumeAnalytics,
  EmployeesSurvey,
  CampusHiringPage,
} from "./pages";
import ProductsPage from "./pages/products";
import AllBlogsPage from "./components/blog/allBlogs";
function App() {
  return (
    <div>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/home" element={<HomePage />}></Route>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route
          path="/questions-library"
          element={<QuestionLibraryPage />}
        ></Route>
        <Route
          path="/questions-library/:title"
          element={<QuestionDetails />}
        ></Route>

        <Route path="/blog/:id" element={<BlogPage />}></Route>

        <Route path="/blogs" element={<AllBlogsPage />}></Route>
        <Route path="/products" element={<ProductsPage />}></Route>
        <Route path="/resume-parser" element={<ResumeParserPage />}></Route>
        <Route path="/results" element={<ResumeAnalytics />}></Route>
        <Route path="/proctoring" element={<ProctoringPage />}></Route>
        <Route path="/campus-hiring" element={<CampusHiringPage />}></Route>
        <Route
          path="/video-interview-platform"
          element={<VideoInterviewPage />}
        ></Route>
        <Route
          path="/online-assessment-platform"
          element={<OnlineAssessmentPage />}
        ></Route>
        <Route path="/employees-survey" element={<EmployeesSurvey />}></Route>
        <Route path="/tni" element={<TNI />}></Route>
        <Route path="/pricing" element={<PricingPage />}></Route>
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/free-trial" element={<FreeTrialPage />} />
        <Route path="/quiz-demo" element={<DemoQuizPage />} />
        <Route path="/faqs" element={<FrequentlyAskedQuestionPage />} />
        <Route path="/terms&conditions" element={<TermsAndConditions />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/GDPR_Policy" element={<GDPRPolicy />} />
        <Route path="/thanks" element={<ThanksPage />} />
        {/* <Route path="/*" element={<NotFound />} /> */}
      </Routes>
    </div>
  );
}

export default App;
