import screening from "../asset/home/assessment/screening.png";
import general from "../asset/home/assessment/general.png";
import domain from "../asset/home/assessment/domain.png";
import technical from "../asset/home/assessment/technical.png";
import language from "../asset/home/assessment/language.png";
import psychometric from "../asset/home/assessment/psychometric.png";
import oneWayVideo from "../asset/home/assessment/oneway.png";
import algoRise from "../asset/home/assessment/algorise.png";
import excel from "../asset/home/assessment/excel.png";
export const assessmentData = [
  {
    id: 1,
    title: "Screening",
    description:
      "Whether you’re hiring for technical, creative, or administrative positions, our Screening Assessment is an efficient first step to build a shortlist of promising talent and accelerate the hiring process.",
    buttonText: "Know More",
    icon: screening,
  },
  {
    id: 2,
    title: "General",
    description:
      "Ideal for a variety of roles, this assessment helps recruiters quickly gauge candidates' foundational abilities, providing a well-rounded view of their potential to succeed in diverse job functions.",
    buttonText: "View Module",
    icon: general,
  },
  {
    id: 3,
    title: "Domain",
    description:
      "Focused on industry-relevant skills and knowledge, this assessment helps recruiters pinpoint candidates with the expertise needed for success in specialized roles, ensuring alignment with job-specific demands.",
    buttonText: "View Module",
    icon: domain,
  },
  {
    id: 4,
    title: "Technical",
    description:
      "This assessment helps recruiters identify candidates with the technical expertise required for specialized roles, ensuring they possess the critical skills to excel in demanding, tech-focused positions.",
    buttonText: "View Module",
    icon: technical,
  },
  {
    id: 5,
    title: "Language",
    description:
      "This assessment helps recruiters identify candidates with the language abilities necessary for effective communication, ensuring they can excel in roles that demand strong English proficiency.",
    buttonText: "View Module",
    icon: language,
  },
  {
    id: 6,
    title: "Psychometric",
    description:
      "Designed to evaluate personality traits and behavioural tendencies, offering recruiters insights into candidates’ cultural fit, teamwork, and leadership potential to support better role alignment.",
    buttonText: "View Module",
    icon: psychometric,
  },
  {
    id: 7,
    title: "One-way Video",
    description:
      "This module enables candidates to record responses to predefined questions, allowing recruiters to efficiently assess communication skills and personality without the need for live interviews.",
    buttonText: "Know More",
    icon: oneWayVideo,
  },
  {
    id: 8,
    title: "AlgoRise - Coding",
    description:
      "This module tests candidates' coding skills and problem-solving abilities across multiple languages. This tool helps recruiters evaluate technical proficiency and coding expertise for software and tech-focused roles.",
    buttonText: "Know More",
    icon: algoRise,
  },
  {
    id: 9,
    title: "Excel",
    description:
      "Assesses candidates' skills in data analysis, formulas, and functions, helping recruiters find talent with strong technical Excel abilities essential for data-driven and analytical roles.",
    buttonText: "Know More",
    icon: excel,
  },
];

export const assessmentModals = [
  {
    type: "Screening",
    title: "Screening Assessment",
    description:
      "Ambition Hire’s Screening Assessment empowers recruiters to efficiently filter candidates by incorporating quick Yes/No questions into the early stages of the interview process. This simple yet powerful tool helps recruiters swiftly identify candidates who meet essential criteria, ensuring only the best fit move forward in the hiring pipeline.",
    benefits: [
      "Efficient Candidate Filtering: Quickly narrow down a large applicant pool to those who meet essential job criteria, reducing the workload on recruiters.",
      "Improved Hiring Accuracy: By focusing on candidates who pass the initial screening, recruiters can allocate more time and resources to assessing qualified applicants.",
      "Time and Cost Savings: Reduce the effort spent reviewing unqualified resumes by letting the Screening Assessment handle preliminary filtering.",
      "Enhanced Candidate Experience: Simplifies the application process for candidates by providing clear Yes/No questions, ensuring they only move forward if they meet the job requirements.",
    ],
    whenToUse: [
      "High-Volume Hiring: For roles that receive a large number of applications, such as customer service, retail, or entry-level positions, the Screening Assessment helps efficiently sort through candidates.",
      "Specialized Roles with Non-Negotiable Requirements: For positions that require specific certifications, technical skills, or experience, use this tool to filter out candidates who don’t meet the essential prerequisites.",
    ],
    endLines:
      "Ambition Hire’s Screening Assessment is an essential feature for recruiters looking to streamline their initial hiring stages, enabling faster, more accurate, and cost-effective candidate selection.",
  },
  {
    type: "AlgoRise - Coding",
    title: "AlgoRise - Coding Assessment",
    description:
      "Assess technical proficiency and coding expertise effortlessly with AlgoRise, Ambition Hire’s comprehensive coding assessment module. Designed to evaluate candidates' problem-solving abilities and coding skills across multiple programming languages, this tool is perfect for identifying the best fit for your software and tech-focused roles.",
    benefits: [
      "Comprehensive Skill Evaluation: Test candidates' proficiency in multiple programming languages, ensuring you find the right match for your specific tech stack.",
      "In-Depth Problem-Solving Insights: Assess not just coding ability but also how candidates approach and solve complex technical challenges, which is crucial for tech roles requiring innovative thinking.",
      "Accurate Evaluation of Technical Proficiency: Get a clear picture of each candidate’s strengths, whether it’s their ability to write efficient algorithms, debug code, or optimize solutions under constraints.",
      "Streamlined Hiring for Tech Positions: Reduce the time spent on live coding interviews by pre-screening candidates with tailored coding challenges, ensuring only the most qualified candidates move forward.",
    ],
    whenToUse: [
      "Technical Roles Requiring Coding Expertise: Perfect for hiring software developers, data scientists, backend engineers, and other tech positions.",
      "Assessing Junior to Senior-Level Coders: Whether you’re looking for entry-level talent or seasoned professionals, the AlgoRise module allows you to adjust assessment difficulty to match the desired experience level.",
    ],
    endLines:
      "Ambition Hire’s AlgoRise coding assessments provide an efficient, scalable, and precise way to evaluate candidates’ technical abilities, empowering you to make better hiring decisions for your software and tech teams.",
  },
  {
    type: "Excel",
    title: "Excel Assessment",
    description:
      "Ambition Hire’s Excel Skill Assessment module is specifically designed to gauge candidates' proficiency in using Excel for data analysis, formula application, and problem-solving. This tool is ideal for recruiters looking to hire talent for roles where advanced Excel skills are crucial, such as data analysis, financial modeling, or operations management.",
    benefits: [
      "Targeted Skill Evaluation: Assess candidates on their ability to work with Excel formulas, functions, pivot tables, and data visualization techniques, ensuring they can efficiently handle data-related tasks.",
      "Streamlined Assessment Process: Save time by evaluating candidates' Excel proficiency upfront, helping you quickly shortlist those with the technical skills required for data-driven roles.",
      "Essential for Analytical Positions: Perfect for identifying candidates suited for positions in finance, analytics, marketing, and operations where Excel expertise is a must.",
    ],
    whenToUse: [
      "Data Analysis & Reporting Roles: Ideal for hiring analysts, financial planners, marketing specialists, and other positions that rely heavily on Excel for decision-making.",

      "Screening for Advanced Technical Skills: Efficiently filter candidates for mid-to-senior level positions that demand a high degree of Excel competency.",
    ],
    endLines:
      "Ambition Hire’s Excel Skill Assessment empowers you to find candidates with the analytical edge needed to excel in data-driven environments, enhancing the accuracy and speed of your recruitment process.",
  },
  {
    type: "One-way Video",
    title: "One-way Video Interview",
    description:
      "Simplify your recruitment process by leveraging Ambition Hire’s One-Way Video Interview feature. Allow candidates to record responses to predefined questions, enabling recruiters to assess communication skills and personality at their convenience, saving time while maintaining a thorough evaluation.",
    benefits: [
      "Time-Saving Candidate Evaluation: Eliminate the hassle of scheduling live interviews by allowing candidates to respond to questions on their own time. Recruiters can review the recorded responses at their convenience, speeding up the initial stages of hiring.",
      "In-Depth Insights into Communication Skills: By assessing candidates’ verbal responses, recruiters gain valuable insights into their communication style, confidence, and presentation skills—critical factors for roles requiring client interactions or teamwork.",
      "Enhanced Screening Efficiency: Quickly assess a candidate’s personality, motivation, and alignment with company values, allowing for a more informed decision on who progresses to the next stage.",
      "Enhanced Screening Efficiency: Quickly assess a candidate’s personality, motivation, and alignment with company values, allowing for a more informed decision on who progresses to the next stage.",
    ],
    whenToUse: [
      "Early Screening for Communication-Intensive Roles: Ideal for positions like sales, customer support, or marketing where communication skills are essential.",
      "Early Screening for Communication-Intensive Roles: Ideal for positions like sales, customer support, or marketing where communication skills are essential.",
    ],
    endLines:
      "Ambition Hire’s One-Way Video Interview helps recruiters streamline their selection process, providing a flexible, scalable, and insightful way to assess candidates beyond their resumes.",
  },
];
