import React from "react";
import { GradientSection } from "../../ui/wrappers";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";

function ResumeMain({ gradient, color }) {
  return (
    <GradientSection
      nav={true}
      color={color}
      bgColor={color}
      gradient={gradient}
      textColor={"text-green"}
    >
      <div className="flex flex-col relative">
        {/* Headings */}
        <h1 className="text-[24px] lg:text-[48px] font-krona tracking-[-0.05em]  max-w-[895px]">
          Actionable Insights with Result Analytics
        </h1>
        <div className="mt-6">
          <div className="text-[14px] md:text-[22px] font-500 leading-[18.9px] md:leading-[34px] lg:leading-normal mt-4 lg:mt-6 text-[#505050]">
            <p>
              Empower your hiring decisions with Ambition Hire's Result
              Analytics. Gain a comprehensive view of candidate performance and
              make data-driven choices effortlessly.
            </p>

            <p className="mt-5">
              In today’s competitive hiring landscape, data is your greatest
              ally. AmbitionHire’s Result Analytics feature provides recruiters
              with in-depth insights into candidate assessments, helping you
              evaluate performance, identify top talent, and make confident
              hiring decisions.
            </p>
          </div>
        </div>
        {/* Buttons */}
        <div className="mt-6 mb-12 lg:mb-24 lg:mt-[64px] flex flex-col   sm:flex-row gap-4 lg:gap-6">
          <Link
            to={"/quiz-demo"}
            className="flex gap-2 lg:gap-[18px] items-center justify-center bg-violet px-[32px] py-[12px] lg:py-4 rounded-[128px] w-full lg:w-auto"
          >
            <span className="text-[13px] lg:text-[18px] font-krona text-white">
              Schedule a Quick Demo
            </span>
            <p className="bg-white rounded-full grid place-content-center p-[5.455px] lg:p-[7.273px]">
              <FaArrowRight className={`text-[20px] text-violet`} />
            </p>
          </Link>
        </div>
      </div>
    </GradientSection>
  );
}

export default ResumeMain;
