import React from "react";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import { Helmet } from "react-helmet";
import VideoInterviewMain from "../../components/videoInterview/videoInterviewMain";
import VideoInterviewSection2 from "../../components/videoInterview/videoInterviewSection2";
import VideoInterviewSection3 from "../../components/videoInterview/videoInterviewSection3";
import EmployeesSurveyMain from "../../components/employeeSurvey/employeeSurveyMain";
import EmployeesSurveySection2 from "../../components/employeeSurvey/employeeSurveySection2";
import EmployeesSurveySection3 from "../../components/employeeSurvey/employeeSurveySection3";

function EmployeesSurveyPage() {
  const gradient =
    "linear-gradient(115.03deg, rgba(58, 189, 181, 0.08) 17.08%, rgba(255, 255, 255, 0.08) 39.71%, rgba(58, 189, 181, 0.16) 63.92%)";
  const color = "#3ABDB5";
  return (
    <PageLayout>
      {/* <ProductsMain/> */}
      <Helmet>
        <title>
          Employees’ Surveys - Empowering Insightful Workforce Decisions
        </title>
        <meta
          name="description"
          content="Video interview platform offers seamless integration, automatic interview capture, and advanced candidate evaluation features to ensure efficient and effective remote hiring."
        />
        <link rel="canonical" href="https://ambitionhire.ai/employees-survey" />
      </Helmet>
      <RevealAnimation>
        <EmployeesSurveyMain gradient={gradient} color={color} />
      </RevealAnimation>
      <RevealAnimation>
        <EmployeesSurveySection2 gradient={gradient} color={color} />
      </RevealAnimation>
      <RevealAnimation>
        <EmployeesSurveySection3 gradient={gradient} color={color} />
      </RevealAnimation>
    </PageLayout>
  );
}

export default EmployeesSurveyPage;
