import React, { useEffect, useState } from "react";
import resumeTab from "../../../../asset/home/resumeParser/resume-tablet.png";
import resumeDesktop from "../../../../asset/home/resumeParser/resume-desktop.png";
import resumeMobile from "../../../../asset/home/resumeParser/resume-mobile.png";
import { Offer_Box } from "../../../../ui/cards";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function ResumeParser() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/resume-parser");
    scrollToTop();
  };

  return (
    <>
      <div
        onClick={handleClick}
        className=" md:relative p-0 m-0 justify-between h-[322px] md:h-[364px] w-full md:w-[247px] rounded-[24px] cursor-pointer overflow-hidden md:transition-shadow duration-300 ease-in-out md:hover:shadow-[0px_8px_24px_0px_rgba(0,0,0,0.08)]"
      >
        <img
          src={resumeDesktop}
          alt="Resume Desktop Background"
          className="absolute inset-0 w-full h-full  object-cover  hidden md:flex"
        />
        <img
          src={resumeTab}
          alt="Resume Tab Background"
          className="absolute inset-0 w-full h-full hidden object-cover sm:flex md:hidden rounded-3xl"
        />
        <img
          src={resumeMobile}
          alt="Resume Mobile Background"
          className="absolute inset-0 w-full h-full  object-cover flex sm:hidden rounded-3xl"
        />
        <div className="relative z-10 p-6 flex flex-col justify-between md:justify-start h-full">
          <p className="font-satoshi font-900 text-lg text-[#CECECE] leading-[25px]">
            07
          </p>
          <div className="w-full sm:w-[330px]  md:w-full ">
            <p className="text-2xl text-[#1E1B1E] font-satoshi font-700 leading-[33px] py-2 text-nowrap">
              Resume Parser
            </p>
            <div>
              <p className="text-base font-satoshi font-medium text-[#626062] leading-[22px]">
                Optimize credit use and cut costs by making data-driven
                decisions on candidate assessments. Upload resumes and jobs to
                get suitability scores and stack rankings, identifying the
                best-fit roles efficiently.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
