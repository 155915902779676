import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
function OutlinedButton({ text, borderColor, textColor, py, onClick }) {
  return (
    <div onClick={onClick}>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.1 }}
        className={`border-[3px] ${borderColor} ${textColor} rounded-[128px] btn w-full sm:w-[272px] lg:w-fit py-3 `}
      >
        <span className="leading-[16.25px] lg:leading-[22.5px] text-[14px] lg:text-[18px] font-krona whitespace-nowrap">
          {text}
        </span>
      </motion.button>
    </div>
  );
}

export default OutlinedButton;
