import React from "react";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import { Helmet } from "react-helmet";
import ResumeParserMain from "../../components/resumeParser/resumeParserMain";
import ResumeParserSection2 from "../../components/resumeParser/resumeParserSection2";
import ResumeParserSection3 from "../../components/resumeParser/resumeParserSection3";
import CampusHiringMain from "../../components/campusHiring/campusHiringMain";
import CampusHiringSection2 from "../../components/campusHiring/campusHiringSection2";
import CampusHiringSection3 from "../../components/campusHiring/campusHiringSection3";

function CampusHiringPage() {
  const color = "#3883C8";
  const gradient = `linear-gradient(115.03deg, rgba(108, 146, 181, 0.18) 17.08%, rgba(255, 255, 255, 0.08) 39.71%, rgba(108, 146, 181, 0.24) 63.92%)`;
  return (
    <PageLayout>
      {/* <ProductsMain/> */}
      <Helmet>
        <title>
          Revolutionize your campus hiring strategy with Ambition Hire
        </title>
        <meta
          name="description"
          content="AI-powered software ensures accurate extraction of candidate information. Experience unmatched precision and speed in processing resumes."
        />
        <link rel="canonical" href="https://ambitionhire.ai/campus-hiring" />
      </Helmet>
      <RevealAnimation>
        <CampusHiringMain gradient={gradient} color={color} />
      </RevealAnimation>
      <RevealAnimation>
        <CampusHiringSection2 gradient={gradient} color={color} />
      </RevealAnimation>
      <RevealAnimation>
        <CampusHiringSection3 gradient={gradient} color={color} />
      </RevealAnimation>
    </PageLayout>
  );
}

export default CampusHiringPage;
