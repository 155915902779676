import React from "react";
import styles from "./style.module.css";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import Main from "../../components/home/main";
import Hire from "../../components/home/hire";
import Products from "../../components/home/products";
import { Helmet } from "react-helmet";
import Assessment from "../../components/home/assessments";
function HomePage() {
  return (
    <PageLayout className="">
      <Helmet>
        <title>AI powered candidate tracking and recruitment software</title>
        <meta
          name="description"
          content="Revolutionize your recruitment process with our wide range of assessments and proctoring-enabled platform"
        />
        <link rel="canonical" href="https://ambitionhire.ai" />
      </Helmet>
      {/* Main Landing */}
      <RevealAnimation>
        <Main />
      </RevealAnimation>
      {/* Offer */}
      <RevealAnimation>
        <Products />
      </RevealAnimation>
      {/* Assessments */}
      <RevealAnimation>
        <Assessment />
      </RevealAnimation>
      {/* Hire */}
      <RevealAnimation>
        <Hire />
      </RevealAnimation>
    </PageLayout>
  );
}

export default HomePage;
