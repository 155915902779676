import { Link } from "react-router-dom";
import { GradientSection } from "../../ui/wrappers";
function CampusHiringSection2({ gradient }) {
  const data = [
    {
      title: "Effortless Bulk Hiring",
      text: "Invite thousands of students to apply and complete assessments with a single click, ensuring seamless execution even during high-volume recruitment drives.",
    },
    {
      title: "Customizable Assessments",
      text: "Tailor assessments to specific job roles or skill requirements, ensuring that only the most qualified candidates move forward.",
    },
    {
      title: "Automated Shortlisting",
      text: "Let Ambition Hire handle the initial screening process with AI-driven shortlisting, saving your team valuable time and effort.",
    },
    {
      title: "Integrated Communication Tools",
      text: "Keep candidates informed and engaged with built-in communication features, streamlining interaction and reducing delays.",
    },
  ];

  return (
    <GradientSection
      sectionHeading={"Why Choose Campus Hiring with Ambition Hire?"}
    >
      <div className="mt-8 sm:mt-[64px] lg:mt-24 grid grid-cols-1 md:grid-cols-2 gap-8">
        {data?.map((item, index) => (
          <div
            key={index}
            className="rounded-[24px] p-8"
            style={{ background: gradient }}
          >
            <h3 className="text-base sm:text-xl font-krona leading-5 sm:leading-[25px] text-center">
              {item.title}
            </h3>
            <p className="text-sm sm:text-lg text-[#555555] leading-[18.9px] sm:leading-[26px] font-500 mt-6 sm:mt-8">
              {item.text}
            </p>
          </div>
        ))}
      </div>
    </GradientSection>
  );
}

export default CampusHiringSection2;
