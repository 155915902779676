import { GradientSection } from "../../ui/wrappers";
function TNISection2({ gradient }) {
  const data = [
    {
      title: "Targeted Apprentice Placement",
      text: "For companies hiring apprentices, TNI helps evaluate candidates’ capabilities to ensure they are placed in roles where they can thrive. With diverse assessment modules tailored to different departments, recruiters can measure candidates’ skill levels and aptitudes for each specific role. This structured approach to apprentice placement not only improves department alignment but also boosts productivity and long-term success within teams.",
    },
    {
      title: "Informed Decision-Making for Employee Development",
      text: "By providing a structured assessment framework, TNI gives employers a clear picture of employee performance, readiness for advancement, and areas for improvement. This helps ensure that recognition and development are based on reliable data, fostering a culture of merit and growth.",
    },
    {
      title: "Diverse Module Options",
      text: "With TNI, companies have access to a variety of assessment modules, from technical and analytical skills to domain-specific and cognitive abilities. This flexibility allows organizations to create tailored assessments that accurately reflect the skills and traits needed across different roles and departments.",
    },
  ];

  return (
    <GradientSection sectionHeading={"Key Features"}>
      {
        <div className="flex flex-col  md:flex-row gap-8 pt-24 justify-center items-center md:items-start">
          <div
            className="rounded-[24px] p-8 h-fit w-full md:max-w-[475px]"
            style={{ background: gradient }}
          >
            <h3 className="text-lg md:text-xl font-krona leading-[22.6px] md:leading-[25px] text-center">
              {data[0].title}
            </h3>
            <p className="text-sm md:text-lg text-[#555555] leading-[18.9px] md:leading-[26px] font-500 mt-6 md:mt-8">
              {data[0].text}
            </p>
          </div>
          <div className="flex flex-col h-fit w-full md:max-w-[475px]">
            <div
              className="rounded-[24px] p-8 mt-8 md:mt-0 w-fit "
              style={{ background: gradient }}
            >
              <h3 className="text-lg md:text-xl font-krona leading-[22.6px] md:leading-[25px] text-center">
                {data[1].title}
              </h3>
              <p className="text-sm md:text-lg text-[#555555] leading-[18.9px] md:leading-[26px] font-500 mt-6 md:mt-8">
                {data[1].text}
              </p>
            </div>
            <div
              className="rounded-[24px] p-8 mt-8 h-fit w-full md:max-w-[475px]"
              style={{ background: gradient }}
            >
              <h3 className="text-lg md:text-xl font-krona leading-[22.6px] md:leading-[25px] text-center">
                {data[2].title}
              </h3>
              <p className="text-sm md:text-lg text-[#555555] leading-[18.9px] md:leading-[26px] font-500 mt-6 md:mt-8">
                {data[2].text}
              </p>
            </div>
          </div>
        </div>
      }
    </GradientSection>
  );
}

export default TNISection2;
