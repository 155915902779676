import { GradientBorder, GradientSection } from "../../ui/wrappers";
import { Icons } from "../../asset";
function TNISection3({ gradient, color }) {
  const features = [
    {
      title: "Apprentice and Entry-Level Hiring",
      text: " - For companies that rely on apprenticeships or rotational programs, TNI helps identify candidates’ potential and assigns them to suitable departments",
    },
    {
      title: "Employee Appraisals and Promotions",
      text: " - Use TNI to perform periodic assessments that inform decisions on promotions, bonuses, and other recognitions, aligning rewards with performance",
    },
  ];

  const benefitsOfTni = [
    {
      title: "Enhanced Placement Accuracy",
      text: " - Match apprentices to the right departments with tailored assessments, maximizing their strengths and contributions from day one.",
    },
    {
      title: "Data-Driven Decision-Making",
      text: " - Make objective, data-backed decisions for promotions, bonuses, and appraisals, helping recognize and reward talent effectively.",
    },
    {
      title: "Comprehensive Skill Evaluation",
      text: "With multiple modules covering key functional areas, TNI provides a well-rounded view of candidate and employee capabilities.",
    },
    {
      title: "Increased Employee Motivation",
      text: " - By linking promotions and bonuses to performance-based assessments, companies can motivate employees to improve and excel in their roles.",
    },
    {
      title: "Seamless Integration with Hiring and Evaluation Processes",
      text: " - TNI integrates with existing recruitment and evaluation workflows, simplifying assessment management and results analysis.",
    },
  ];

  return (
    <GradientSection sectionHeading={"When to Use Ambition Hire’s TNI Tool"}>
      <p className="my-8 text-center text-sm md:text-[22px] font-500 leading-[18.9px] md:leading-[34px] text-primary-light px-10">
        Ambition Hire’s TNI tool offers a strategic approach to workforce
        evaluation, helping companies place, develop, and reward talent more
        effectively. This feature is an essential asset for companies committed
        to data-driven employee development and optimal talent utilization
      </p>
      <div className="mt-8 sm:mt-[64px] lg:mt-24 flex flex-col gap-6 mb-32">
        {/* Features */}
        {features?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8"
            style={{ background: "#8CB56C0D" }}
          >
            <div className="text-base md:text-2xl leading-[21.6px] md:leading-[32.3px] text-primart-light font-700 flex gap-4">
              <div className="h-6 w-6 mt-2">
                <Icons.Asterisk color={color} />
              </div>

              <p>
                <span className="font-700">{item?.title}</span>{" "}
                <span className="font-500">{item?.text}</span>
              </p>
            </div>
          </div>
        ))}

        {/* Advanced Features */}
        <h2 className="text-xl md:text-[32px] leading-[25px] md:leading-10 tracking-[-0.05em] text-center font-krona mt-24 md:mt-48 mb-12 md:mb-20">
          Benefits of Ambition Hire’s TNI Tool
        </h2>
        {benefitsOfTni?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8"
            style={{ background: "#8CB56C0D" }}
          >
            <div className="text-base md:text-2xl leading-[21.6px] md:leading-[32.3px] text-primart-light font-700 flex gap-4">
              <div className="h-6 w-6 mt-2">
                <Icons.CircleCheck color={color} />
              </div>

              <p>
                <span className="font-700">{item?.title}</span>{" "}
                <span className="font-500">{item?.text}</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </GradientSection>
  );
}

export default TNISection3;
