import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import CandidateInvitation from "./employess_survey";
import Proctoring from "./proctoring";
import Result from "./result";
import ResumeParser from "./resumeParser";
import Tni from "./tni";
import PreHiring from "./pre_hiring";
import CampusHiring from "./campus_hiring";
import EmployeesSurvey from "./employess_survey";

export default function Products() {
  const tabs = [
    { name: "Pre-Hiring Assessments", component: <PreHiring /> },
    { name: "Proctoring & Anti-Cheat", component: <CampusHiring /> },
    { name: "Employees Survey", component: <EmployeesSurvey /> },
    { name: "TNI", component: <Tni /> },
    { name: "Proctoring & Anti-Cheat", component: <Proctoring /> },
    { name: "Result Analytics", component: <Result /> },
    { name: "Resume Parser", component: <ResumeParser /> },
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };
  const renderIndicators = () => {
    // Calculate the range to display 3 indicators at a time
    let visibleRangeStart = Math.max(activeIndex - 1, 0); // Ensure we don't go below index 0
    let visibleRangeEnd = Math.min(activeIndex + 1, tabs.length - 1); // Ensure we don't exceed the last index

    // If the visible range doesn't span 3 items, adjust to center around the activeIndex
    const rangeLength = visibleRangeEnd - visibleRangeStart + 1;
    if (rangeLength < 3) {
      if (visibleRangeStart === 0) {
        visibleRangeEnd = Math.min(2, tabs.length - 1); // Show first 3 indicators if at the start
      } else if (visibleRangeEnd === tabs.length - 1) {
        visibleRangeStart = Math.max(tabs.length - 3, 0); // Show last 3 indicators if at the end
      }
    }

    return tabs.map((_, index) => {
      // Determine if the indicator should be visible (within the calculated range)
      const isVisible = index >= visibleRangeStart && index <= visibleRangeEnd;

      // Indicator styling logic
      let styles = {
        display: isVisible ? "block" : "none",
        borderRadius: "50%",
        transition: "width 0.3s, height 0.3s",
      };

      if (index === activeIndex) {
        styles = {
          ...styles,
          width: "24px",
          height: "12px",
          borderRadius: "6px",
          background: "#7D4996",
        };
      } else if (index === activeIndex + 1) {
        styles = {
          ...styles,
          width: "12px",
          height: "12px",
          background: "#7D499699",
          opacity: 0.6,
        };
      } else {
        styles = {
          ...styles,
          width: "10px",
          height: "10px",
          background: "#7D49961A",
        };
      }

      return <div key={index} style={styles} />;
    });
  };

  return (
    <div className="bg-[#FAFAFA] pb-[120px]">
      <section className="wrapper">
        <h2 className="section_heading mt-[64px] md:mt-[120px]">
          Products we offer
        </h2>
        <h3 className=" text-sm md:text-xl font-medium text-center mt-4 font-satoshi">
          Discover our suite of advanced hiring solutions designed to streamline
          recruitment, enhance assessments, and find top talent effortlessly
        </h3>

        {/* Carousel for mobile and tablet */}
        <div className="pt-[56px] md:hidden ">
          <Swiper
            modules={[Autoplay]}
            onSlideChange={handleSlideChange}
            spaceBetween={20}
            slidesPerView={1}
            autoplay={{
              delay: 5000, // Time in milliseconds for auto-scroll
              disableOnInteraction: true, // Resume autoplay after interaction
            }}
            loop={true}
          >
            {tabs.map((tab, index) => (
              <SwiperSlide key={index}>
                <div className="p-4 bg-white shadow-md h-fit rounded-3xl">
                  {tab.component}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* Custom Indicators */}
          <div className="flex justify-center gap-2 pt-4">
            {renderIndicators()}
          </div>
        </div>

        {/* Original layout for desktop */}
        <div className="hidden md:block">
          <div className="flex flex-col pt-[56px] gap-4">
            <div className="flex gap-4">
              <PreHiring />
              <CampusHiring />
            </div>
            <div className="flex gap-4">
              <EmployeesSurvey />
              <Tni />
            </div>
            <div className="flex gap-4">
              <Proctoring />
              <Result />
              <ResumeParser />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
