import React from "react";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import { Helmet } from "react-helmet";
import ResumeMain from "../../components/result/resultMain";
import ResumeSection2 from "../../components/result/resultSection2";
import ResumeSection3 from "../../components/result/resultSection3";

function ResumeAnalytics() {
  const gradient =
    "linear-gradient(115.03deg, rgba(142, 134, 226, 0.24) 17.08%, rgba(226, 223, 254, 0.12) 39.71%, rgba(139, 130, 233, 0.32) 63.92%)";
  const color = "#8079C8";
  return (
    <PageLayout>
      {/* <ProductsMain/> */}
      <Helmet>
        <title>Actionable Insights with Result Analytics</title>
        <meta
          name="description"
          content="Actionable Insights with Result Analytics"
        />
        <link rel="canonical" href="https://ambitionhire.ai/result-analytics" />
      </Helmet>
      <RevealAnimation>
        <ResumeMain gradient={gradient} color={color} />
      </RevealAnimation>
      <RevealAnimation>
        <ResumeSection2 gradient={gradient} color={color} />
      </RevealAnimation>
      <RevealAnimation>
        <ResumeSection3 gradient={gradient} color={color} />
      </RevealAnimation>
    </PageLayout>
  );
}

export default ResumeAnalytics;
