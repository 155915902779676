import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
function ContactUsButton({ text, borderColor, textColor, py, link }) {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Link to={link} onClick={scrollToTop} className="w-full">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.1 }}
        className={`border-[3px] ${borderColor} ${textColor} rounded-[128px] btn w-full sm:w-[272px] lg:w-fit ${py} lg:py-[20px] `}
      >
        <span className="leading-[16.25px] lg:leading-[22.5px] text-[14px] lg:text-[18px] font-krona whitespace-nowrap">
          {text}
        </span>
      </motion.button>
    </Link>
  );
}

export default ContactUsButton;
