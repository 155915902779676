import React from "react";
import { motion } from "framer-motion";

function PrimaryGradientButton({ text, onClick, isSelected }) {
  const gradient = isSelected
    ? "linear-gradient(180deg, #7D4996 0%, #FCF6FF 254.69%)"
    : "linear-gradient(180deg, #FFFFFF 0%, #DFDFDF 254.69%)";

  return (
    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.1 }}
      onClick={onClick}
      style={{
        background: gradient,
        boxShadow: "0px 8px 24px 0px #7D499633",
        color: isSelected ? "#FFFFFF" : "#1E1B1E",
      }}
      className="flex w-full md:w-auto gap-2 items-center justify-center px-8 py-4 rounded-[128px] 
               text-xl md:text-[24px] font-bold opacity-100 font-satoshi lg:text-nowrap"
    >
      <span>{text}</span>
    </motion.button>
  );
}

export default PrimaryGradientButton;
