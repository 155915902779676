import React from "react";
import arrow from "../../asset/general/arrow-right.svg";
import { FaArrowRight } from "react-icons/fa6";
import { motion } from "framer-motion";
function ContactButton({ text, contact, symbol, textColor }) {
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.1 }}
      className="bg-violet text-white flex justify-center items-center btn rounded-[24px] w-full  space-x-2  py-2 lg:py-[16px]"
    >
      <div>
        <div className="flex items-center justify-center">
          <img src={symbol} className="" />
          <p className="font-krona tracking-normal text-[16px] leading-[20px] lg:leading-[22.5px] lg:text-[18px]">
            {text}
          </p>
        </div>
        <p className="font-satoshi text-[14px] lg:text-[16px] leading-[18.9px] lg:leading-[21.6px] font-500 whitespace-normal">
          {contact}
        </p>
      </div>
      <p className={` rounded-full p-1  bg-white`}>
        <FaArrowRight className={`text-[16px] lg:text-[18px] ${textColor}`} />
      </p>
    </motion.button>
  );
}

export default ContactButton;
