import React from "react";
import { useNavigate } from "react-router-dom";
import preHiringDesktop from "../../../../asset/home/pre-hiring/pre-hiring - desktop.png";
import preHiringTab from "../../../../asset/home/pre-hiring/pre-hiring-assessments-tablet.png";
import preHiringMobile from "../../../../asset/home/pre-hiring/pre-hiring-assessments-mobile.png";

export default function PreHiring() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/online-assessment-platform");
    scrollToTop();
  };

  return (
    <div
      onClick={handleClick}
      className=" md:relative p-0 m-0 h-[322px] md:h-[364px] w-full md:w-[658px] rounded-[24px] cursor-pointer overflow-hidden md:transition-shadow duration-300 ease-in-out md:hover:shadow-[0px_8px_24px_0px_rgba(0,0,0,0.08)]"
    >
      {/* Background Image */}
      <img
        src={preHiringDesktop}
        alt="Proctoring Tab Background"
        className="absolute inset-0 w-full h-full  object-cover  hidden md:flex"
      />
      <img
        src={preHiringTab}
        alt="Proctoring Tab Background"
        className="absolute inset-0 w-full h-full hidden object-cover sm:flex md:hidden rounded-3xl"
      />
      <img
        src={preHiringMobile}
        alt="Proctoring Tab Background"
        className="absolute inset-0 w-full h-full  object-cover flex sm:hidden rounded-3xl"
      />

      {/* Overlay Content */}
      <div className="relative z-10 flex flex-col items-start justify-between h-full p-6">
        {/* Number */}
        <p className="font-satoshi font-900 text-lg text-[#CECECE] leading-[25px]">
          01
        </p>

        {/* Text Content */}
        <div className="w-full sm:max-w-[340px] md:max-w-[305px] xl:max-w-[340px]">
          <p className="text-2xl text-[#1E1B1E] font-satoshi font-700 leading-[33px] pb-2">
            Pre-Hiring Assessments
          </p>
          <p className="text-base font-satoshi font-medium text-[#626062] leading-[22px]">
            We provide companies with tailored tools to evaluate candidates for
            both technical and non-technical roles. These assessments are
            designed to measure skills, aptitude, and competencies, ensuring
            recruiters make informed decisions while streamlining the hiring
            process for better-fit candidates.
          </p>
        </div>
      </div>
    </div>
  );
}
