import React from "react";
import { PageLayout, RevealAnimation } from "../../ui/wrappers";
import { Helmet } from "react-helmet";
import OnlineAssessmentSection2 from "../../components/onlineAssessment/onlineAssessmentSection2";
import OnlineAssessmentSection3 from "../../components/onlineAssessment/onlineAssessmentSection3";
import TNIMain from "../../components/tni/tniMain";
import TNISection2 from "../../components/tni/tniSection2";
import TNISection3 from "../../components/tni/tniSection3";

function TNI() {
  const gradient =
    "linear-gradient(115.03deg, rgba(142, 134, 226, 0.24) 17.08%, rgba(226, 223, 254, 0.12) 39.71%, rgba(139, 130, 233, 0.32) 63.92%)";
  const color = "#8079C8";
  return (
    <PageLayout>
      {/* <ProductsMain/> */}
      <Helmet>
        <title>Training Needs Identification</title>
        <meta name="description" content="Training Needs Identification" />
        <link rel="canonical" href="https://ambitionhire.ai/tni" />
      </Helmet>
      <RevealAnimation>
        <TNIMain gradient={gradient} color={color} />
      </RevealAnimation>
      <RevealAnimation>
        <TNISection2 gradient={gradient} color={color} />
      </RevealAnimation>
      <RevealAnimation>
        <TNISection3 gradient={gradient} color={color} />
      </RevealAnimation>
    </PageLayout>
  );
}

export default TNI;
