import React from "react";
import { GradientBorder, GradientSection } from "../../ui/wrappers";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";

function EmployeesSurveyMain({ gradient, color }) {
  return (
    <GradientSection
      nav={true}
      color={color}
      bgColor={color}
      gradient={gradient}
      textColor={"text-light-green"}
    >
      <div className="flex flex-col relative">
        {/* Headings */}
        <h1 className="text-[24px] lg:text-[48px] font-krona tracking-[-0.05em]  max-w-[781px]">
          Employees’ Surveys - Empowering Insightful Workforce Decisions
        </h1>
        <div className="mt-6">
          <div className="text-[14px] md:text-[22px] font-500 leading-[18.9px]  md:leading-[34px] mt-4 lg:mt-6 text-[#505050]">
            <p>
              Understand your employees better and foster a thriving workplace
              with Ambition Hire’s Employees’ Surveys. Gain meaningful insights
              into employee satisfaction, engagement, and organizational culture
              to drive impactful decisions.
            </p>

            <p className="mt-5">
              Employees’ Surveys by AmbitionHire empower organizations to unlock
              the true potential of their teams by understanding their needs and
              sentiments. Whether it’s gauging satisfaction, assessing
              engagement levels, or exploring workplace culture, our surveys
              provide the data you need to build a more connected, productive,
              and loyal workforce.
            </p>
          </div>
        </div>
        {/* Buttons */}
        <div className="mt-6 mb-12 lg:mb-24 lg:mt-[64px] flex flex-col   sm:flex-row gap-4 lg:gap-6">
          <Link
            to={"/quiz-demo"}
            className="flex gap-2 lg:gap-[18px] items-center justify-center bg-light-green px-[32px] py-[12px] lg:py-4 rounded-[128px] w-full lg:w-auto"
          >
            <span className="text-[13px] lg:text-[18px] font-krona text-white">
              Schedule a Quick Demo
            </span>
            <p className="bg-white rounded-full grid place-content-center p-[5.455px] lg:p-[7.273px]">
              <FaArrowRight className={`text-[20px] text-light-green`} />
            </p>
          </Link>
          {/* <Link to={'/quiz-demo'} className='font-krona text[14px] lg:text-[18px] text-light-green border-[3px] border-light-green px-[40px] py-[12px] lg:py-[20px] rounded-[128px] whitespace-nowrap'>
            Explore Assesst LIbrary
          </Link> */}
        </div>

        {/* Trust Box */}
        <div
          className="relative top-32 lg:top-48 border py-12 px-8 flex flex-col gap-8 rounded-[16px] bg-white mb-12 lg:mb-24"
          style={{ boxShadow: "0px 16px 36px 0px #5A5A5A14" }}
        >
          <h3 className="text-xl lg:text-[32px] font-krona tracking-[-0.05em] leading-6 lg:leading-10 text-center">
            Take the First Step Toward Understanding Your Team Better
          </h3>
          <p className="text-sm lg:text-xl leading-[18.9px] lg:leading-[34px] text-center text-primary-light">
            Ready to transform your workplace? Get started with Ambition Hire’s
            Employees’ Surveys today and pave the way for a stronger, more
            connected workforce.
          </p>
        </div>
      </div>
    </GradientSection>
  );
}

export default EmployeesSurveyMain;
