import React from "react";
import { useNavigate } from "react-router-dom";
import proctoringTab from "../../../../asset/home/proctoring/proctoring-tablet.png";
import proctoringMobile from "../../../../asset/home/proctoring/proctoring-mobile.png";
import proctoringDesktop from "../../../../asset/home/proctoring/proctoring-desktop.png";

export default function Proctoring() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/proctoring");
    scrollToTop();
  };

  return (
    <div
      onClick={handleClick}
      className=" md:relative p-0 m-0 h-[322px] md:h-[364px] w-full md:w-[658px] rounded-[24px] cursor-pointer overflow-hidden md:transition-shadow duration-300 ease-in-out md:hover:shadow-[0px_8px_24px_0px_rgba(0,0,0,0.08)]"
    >
      {/* Background Image */}
      <img
        src={proctoringDesktop}
        alt="Proctoring Tab Background"
        className="absolute inset-0 w-full h-full  object-cover  hidden md:flex"
      />
      <img
        src={proctoringTab}
        alt="Proctoring Tab Background"
        className="absolute inset-0 w-full h-full hidden object-cover sm:flex md:hidden rounded-3xl"
      />{" "}
      <img
        src={proctoringMobile}
        alt="Proctoring Tab Background"
        className="absolute inset-0 w-full h-full  object-cover flex sm:hidden rounded-3xl"
      />
      {/* Overlay Content */}
      <div className="relative z-10 flex flex-col items-start justify-between h-full p-6">
        {/* Number */}
        <p className="font-satoshi font-900 text-lg text-[#CECECE] leading-[25px]">
          05
        </p>

        {/* Text Content */}
        <div className="w-full sm:max-w-[330px] md:max-w-[305px] xl:max-w-[350px]">
          <p className="text-2xl text-[#1E1B1E] font-satoshi font-700 leading-[33px] pb-2">
            Proctoring & Anti-Cheat
          </p>
          <p className="text-base font-satoshi font-medium text-[#626062] leading-[22px]">
            We ensure assessment integrity with proctoring and anti-cheat
            solutions for reliable evaluations. It detects no-face, multi-face,
            and multi-voice with customizable thresholds and browser
            restrictions. Video proctoring flags suspicious moments and filters
            out dishonest candidates.
          </p>
        </div>
      </div>
    </div>
  );
}
