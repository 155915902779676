import { GradientSection } from "../../ui/wrappers";
function EmployeesSurveySection2({ gradient }) {
  const data = [
    {
      title: "Workplace Culture Assessment",
      text: "Understand how employees perceive your organization’s culture and values.",
    },
    {
      title: "Engagement & Satisfaction Check",
      text: "Regularly monitor employee satisfaction and engagement levels to identify areas needing attention.",
    },
    {
      title: "Post-Initiative Feedback",
      text: "Assess the impact of newly implemented policies, processes, or events.",
    },
    {
      title: "Retention Strategy Development",
      text: "Address concerns and improve retention by understanding the reasons behind employee dissatisfaction.",
    },
  ];

  return (
    <GradientSection sectionHeading={"When to Use Employees’ Surveys?"}>
      <div className="flex flex-col  md:flex-row gap-8 pt-24 justify-center items-center md:items-start">
        <div className="flex flex-col h-fit w-full md:max-w-[475px]">
          <div
            className="rounded-[24px] p-8 h-fit w-full md:max-w-[475px]"
            style={{ background: gradient }}
          >
            <h3 className="text-lg md:text-xl font-krona leading-[22.6px] md:leading-[25px] text-center">
              {data[0].title}
            </h3>
            <p className="text-sm md:text-lg text-[#555555] leading-[18.9px] md:leading-[26px] font-500 mt-6 md:mt-8">
              {data[0].text}
            </p>
          </div>
          <div
            className="rounded-[24px] p-8 mt-8 h-fit w-full md:max-w-[475px]"
            style={{ background: gradient }}
          >
            <h3 className="text-lg md:text-xl font-krona leading-[22.6px] md:leading-[25px] text-center">
              {data[2].title}
            </h3>
            <p className="text-sm md:text-lg text-[#555555] leading-[18.9px] md:leading-[26px] font-500 mt-6 md:mt-8">
              {data[2].text}
            </p>
          </div>
        </div>
        <div className="flex flex-col h-fit w-full md:max-w-[475px]">
          <div
            className="rounded-[24px] p-8 mt-8 md:mt-0 w-fit "
            style={{ background: gradient }}
          >
            <h3 className="text-lg md:text-xl font-krona leading-[22.6px] md:leading-[25px] text-center">
              {data[1].title}
            </h3>
            <p className="text-sm md:text-lg text-[#555555] leading-[18.9px] md:leading-[26px] font-500 mt-6 md:mt-8">
              {data[1].text}
            </p>
          </div>
          <div
            className="rounded-[24px] p-8 mt-8 h-fit w-full md:max-w-[475px]"
            style={{ background: gradient }}
          >
            <h3 className="text-lg md:text-xl font-krona leading-[22.6px] md:leading-[25px] text-center">
              {data[3].title}
            </h3>
            <p className="text-sm md:text-lg text-[#555555] leading-[18.9px] md:leading-[26px] font-500 mt-6 md:mt-8">
              {data[3].text}
            </p>
          </div>
        </div>
      </div>
    </GradientSection>
  );
}

export default EmployeesSurveySection2;
