import { GradientBorder, GradientSection } from "../../ui/wrappers";
import { Icons } from "../../asset";
function ResumeSection3({ gradient, color }) {
  const features = [
    {
      title: "High-Volume Hiring",
      text: " - Analyze large pools of candidate data without compromising accuracy or efficiency.",
    },
    {
      title: "Specialized Roles",
      text: " - Evaluate technical and niche skills with targeted metrics to identify the most suitable candidates.",
    },
    {
      title: "Performance Benchmarking",
      text: " - Set benchmarks for specific roles and compare candidates against them for consistent evaluations.",
    },
    {
      title: "Training Needs Identification",
      text: " - Identify gaps in current employee skills for professional development or career growth programs.",
    },
  ];

  const benefitsOfTni = [
    {
      title: "Informed Decisions",
      text: " - Base your hiring choices on measurable data rather than subjective impressions, reducing the chances of mis-hires.",
    },
    {
      title: "Time Efficiency",
      text: " - Quickly identify high-performing candidates without manually reviewing every detail.",
    },
    {
      title: "Transparency",
      text: " - Provide clear, data-backed reasoning for hiring decisions to internal teams and stakeholders.",
    },
    {
      title: "Bias Reduction",
      text: " - tandardized performance metrics ensure fair evaluations across all candidates.",
    },
    {
      title: "Ongoing Improvement",
      text: " - Use analytics to refine your assessment strategies and improve future recruitment outcomes.",
    },
  ];

  return (
    <GradientSection sectionHeading={"When to Use Result Analytics?"}>
      <p className="my-8 text-center text-sm md:text-[22px] font-500 leading-[18.9px] md:leading-[34px] text-primary-light px-10">
        Stop relying on guesswork. With Result Analytics, every hiring decision
        is backed by comprehensive data, giving you the confidence to recruit
        the best candidates for your business.
      </p>
      <div className="mt-8 sm:mt-[64px] lg:mt-24 flex flex-col gap-6 mb-32">
        {/* Features */}
        {features?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8"
            style={{ background: "#8CB56C0D" }}
          >
            <div className="text-base md:text-2xl leading-[21.6px] md:leading-[32.3px] text-primart-light font-700 flex gap-4">
              <div className="h-6 w-6 mt-2">
                <Icons.Asterisk color={color} />
              </div>

              <p>
                <span className="font-700">{item?.title}</span>{" "}
                <span className="font-500">{item?.text}</span>
              </p>
            </div>
          </div>
        ))}

        {/* Advanced Features */}
        <h2 className="text-xl md:text-[32px] leading-[25px] md:leading-10 tracking-[-0.05em] text-center font-krona mt-24 md:mt-48 mb-12 md:mb-20">
          Key Benefits
        </h2>
        {benefitsOfTni?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8"
            style={{ background: "#8CB56C0D" }}
          >
            <div className="text-base md:text-2xl leading-[21.6px] md:leading-[32.3px] text-primart-light font-700 flex gap-4">
              <div className="h-6 w-6 mt-2">
                <Icons.CircleCheck color={color} />
              </div>

              <p>
                <span className="font-700">{item?.title}</span>{" "}
                <span className="font-500">{item?.text}</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </GradientSection>
  );
}

export default ResumeSection3;
