import { GradientSection } from "../../ui/wrappers";
function ResumeSection2({ gradient }) {
  const data = [
    {
      title: "Informed Decisions",
      text: "Base your hiring choices on measurable data rather than subjective impressions, reducing the chances of mis-hires.",
    },
    {
      title: "Time Efficiency",
      text: "Quickly identify high-performing candidates without manually reviewing every detail.",
    },
    {
      title: "Bias Reduction",
      text: "Standardized performance metrics ensure fair evaluations across all candidates",
    },
    {
      title: "Transparency",
      text: "Provide clear, data-backed reasoning for hiring decisions to internal teams and stakeholders.",
    },
  ];

  return (
    <GradientSection sectionHeading={"Key Features"}>
      {
        <div className="flex flex-col  md:flex-row gap-8 pt-24 justify-center items-center md:items-start">
          <div className="flex flex-col h-fit w-full md:max-w-[475px]">
            <div
              className="rounded-[24px] p-8 h-fit w-full md:max-w-[475px]"
              style={{ background: gradient }}
            >
              <h3 className="text-lg md:text-xl font-krona leading-[22.6px] md:leading-[25px] text-center">
                {data[0].title}
              </h3>
              <p className="text-sm md:text-lg text-[#555555] leading-[18.9px] md:leading-[26px] font-500 mt-6 md:mt-8">
                {data[0].text}
              </p>
            </div>
            <div
              className="rounded-[24px] p-8 mt-8 h-fit w-full md:max-w-[475px]"
              style={{ background: gradient }}
            >
              <h3 className="text-lg md:text-xl font-krona leading-[22.6px] md:leading-[25px] text-center">
                {data[2].title}
              </h3>
              <p className="text-sm md:text-lg text-[#555555] leading-[18.9px] md:leading-[26px] font-500 mt-6 md:mt-8">
                {data[2].text}
              </p>
            </div>
          </div>
          <div className="flex flex-col h-fit w-full md:max-w-[475px]">
            <div
              className="rounded-[24px] p-8 mt-8 md:mt-0 w-fit "
              style={{ background: gradient }}
            >
              <h3 className="text-lg md:text-xl font-krona leading-[22.6px] md:leading-[25px] text-center">
                {data[1].title}
              </h3>
              <p className="text-sm md:text-lg text-[#555555] leading-[18.9px] md:leading-[26px] font-500 mt-6 md:mt-8">
                {data[1].text}
              </p>
            </div>
            <div
              className="rounded-[24px] p-8 mt-8 h-fit w-full md:max-w-[475px]"
              style={{ background: gradient }}
            >
              <h3 className="text-lg md:text-xl font-krona leading-[22.6px] md:leading-[25px] text-center">
                {data[3].title}
              </h3>
              <p className="text-sm md:text-lg text-[#555555] leading-[18.9px] md:leading-[26px] font-500 mt-6 md:mt-8">
                {data[3].text}
              </p>
            </div>
          </div>
        </div>
      }
    </GradientSection>
  );
}

export default ResumeSection2;
