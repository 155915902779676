// Hire_Card component
import { MotionConfig } from "framer-motion";
import React from "react";
import { OutlinedButton } from "../buttons";

function AssessmentCard({
  title,
  icon,
  description = "Description",
  buttonText = "View Modules",
  onClick,
}) {
  return (
    <div className="flex flex-col p-6 gap-8 bg-white mx-3 rounded-[24px] items-center justify-center w-[320px] h-full ">
      <img src={icon} className=" h-[76px] w-[76px]" />
      <div className="flex flex-col text-center">
        <p className="text-2xl font-bold font-satoshi text-[#1E1B1E]">
          {title}
        </p>
        <p className="text-base font-medium mt-4 font-satoshi text-[#626062]">
          {description}
        </p>
      </div>
      <OutlinedButton
        text={buttonText}
        borderColor="border-primary"
        textColor="text-primary"
        onClick={onClick}
      />
    </div>
  );
}

export default AssessmentCard;
