import React, { useState } from "react";
import { Offer_Box } from "../../../../ui/cards";
import tniMobile from "../../../../asset/home/tni/tni-mobile.png";
import tniTab from "../../../../asset/home/tni/tni-tab.png";
import tniDesktop from "../../../../asset/home/tni/tni-desktop.png";
import { useNavigate } from "react-router-dom";

export default function Tni() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/tni");
    scrollToTop();
  };
  return (
    <div
      onClick={handleClick}
      className=" md:relative p-0 m-0 h-[322px] md:h-[364px] w-full md:w-[658px] rounded-[24px] cursor-pointer overflow-hidden md:transition-shadow duration-300 ease-in-out md:hover:shadow-[0px_8px_24px_0px_rgba(0,0,0,0.08)]"
    >
      {/* Background Image */}
      <img
        src={tniDesktop}
        alt="TNI Tab Background"
        className="absolute inset-0 w-full h-full  object-cover  hidden md:flex"
      />
      <img
        src={tniTab}
        alt="TNI Tab Background"
        className="absolute inset-0 w-full h-full hidden object-cover sm:flex md:hidden rounded-3xl"
      />{" "}
      <img
        src={tniMobile}
        alt="TNI Mobile Background"
        className="absolute inset-0 w-full h-full  object-cover flex sm:hidden rounded-3xl"
      />
      {/* Overlay Content */}
      <div className="relative z-10 flex flex-col items-start justify-between h-full p-6">
        {/* Number */}
        <p className="font-satoshi font-900 text-lg text-[#CECECE] leading-[25px]">
          04
        </p>

        {/* Text Content */}
        <div className="w-full sm:max-w-[330px] md:max-w-[305px] xl:max-w-[325px] ">
          <p className="text-2xl text-[#1E1B1E] font-satoshi font-700 leading-[33px] pb-2">
            Training Needs Identification (TNI)
          </p>
          <p className="text-base font-satoshi font-medium text-[#626062] leading-[22px]">
            Identify, assess, and develop talent with Ambition Hire’s Training
            Needs Identification (TNI) tool, tailored to help companies make
            strategic talent decisions. From optimizing apprentice placements to
            guiding bonus and promotion approvals, TNI is designed to elevate
            internal assessments, ensuring candidates and employees align with
            company goals.
          </p>
        </div>
      </div>
    </div>
  );
}
