import { GradientBorder, GradientSection } from "../../ui/wrappers";
import { Icons } from "../../asset";
function CampusHiringSection3({ gradient, color }) {
  const benefits = [
    "Simplify complex hiring processes, enabling faster onboarding and decision-making.",
    "Provide students with a smooth, tech-savvy recruitment experience, strengthening your employer brand.",
    "Minimize operational costs associated with campus hiring by leveraging automated workflows and bulk processing.",
    "Connect with a diverse pool of talented graduates from institutions across the country.",
  ];

  const features = [
    {
      title: "Informed Decisions",
      text: " - Base your hiring choices on measurable data rather than subjective impressions, reducing the chances of mis-hires.",
    },
    {
      title: "Time Efficiency",
      text: " - Quickly identify high-performing candidates without manually reviewing every detail.",
    },
    {
      title: "Transparency",
      text: " - Provide clear, data-backed reasoning for hiring decisions to internal teams and stakeholders.",
    },
    {
      title: "Bias Reduction",
      text: " - tandardized performance metrics ensure fair evaluations across all candidates.",
    },
    {
      title: "Ongoing Improvement",
      text: " - Use analytics to refine your assessment strategies and improve future recruitment outcomes.",
    },
  ];
  return (
    <GradientSection
      sectionHeading={"When to Use Campus Hiring with AmbitionHire?"}
    >
      <p className="my-8 text-center text-sm  md:text-[22px] font-500 leading-[18.9px] md:leading-[34px] text-primary-light px-10">
        With AmbitionHire, campus hiring becomes more than just recruitment;
        it’s a strategic initiative to secure the best talent for your
        organization. By leveraging automation, data-driven insights, and a
        streamlined process, you can focus on building long-term relationships
        with future industry leaders.
      </p>
      <div className="mt-8 sm:mt-[64px] lg:mt-24 flex flex-col gap-6 mb-32">
        {/* Features */}
        {features?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8"
            style={{ background: "#8CB56C0D" }}
          >
            <div className="text-base md:text-2xl leading-[21.6px] md:leading-[32.3px] text-primart-light font-700 flex gap-4">
              <div className="h-6 w-6 mt-2">
                <Icons.Asterisk color={color} />
              </div>

              <p>
                <span className="font-700">{item?.title}</span>{" "}
                <span className="font-500">{item?.text}</span>
              </p>
            </div>
          </div>
        ))}

        {/* Advanced Features */}
        <h2 className="text-xl md:text-[32px] leading-[25px] md:leading-10 tracking-[-0.05em] text-center font-krona mt-24 md:mt-48 mb-12 md:mb-20">
          Key Benefits
        </h2>
        {benefits?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8"
            style={{ background: "#3ABDB50D" }}
          >
            <div className="text-base md:text-2xl leading-[21.6px] md:leading-[32.3px] text-primart-light font-700 flex gap-4">
              <div className="h-6 w-6 ">
                <Icons.CircleCheck color={color} />
              </div>
              <p>{item}</p>
            </div>
          </div>
        ))}
      </div>
    </GradientSection>
  );
}

export default CampusHiringSection3;
