import React from "react";
import { GradientSection } from "../../ui/wrappers";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";

function OnlineAssessmentMain({ gradient, color }) {
  return (
    <GradientSection
      nav={true}
      color={color}
      bgColor={color}
      gradient={gradient}
      textColor={"text-green"}
    >
      <div className="flex flex-col relative">
        {/* Headings */}
        <h1 className="text-[24px] lg:text-[48px] font-krona tracking-[-0.05em]  max-w-[781px]">
          Transform hiring with our Online Assessment Platform
        </h1>
        <div className="mt-6">
          <div className="text-[14px] md:text-[22px] font-500 leading-[18.9px] md:leading-[34px] lg:leading-normal mt-4 lg:mt-6 text-[#505050]">
            <p>
              In today's fast-paced business world, finding the right talent is
              crucial for organizational success. However, sifting through
              countless resumes and conducting numerous interviews can be a
              daunting task. That's where our cutting-edge online assessment
              platform for hiring comes into play, revolutionizing the way you
              evaluate and onboard top candidates
            </p>

            <p className="mt-5">
              Ambition Hire offers a diverse range of assessments, tailored to
              meet the needs of job seekers from various backgrounds and
              industries. Our question bank covers a wide gamut of industries,
              functional domains, and job roles. Whether you're looking for
              software developers, marketing professionals, project managers, or
              candidates in any other field, we've got you covered
            </p>
          </div>
        </div>
        {/* Buttons */}
        <div className="mt-6 mb-12 lg:mb-24 lg:mt-[64px] flex flex-col   sm:flex-row gap-4 lg:gap-6">
          <Link
            to={"/quiz-demo"}
            className="flex gap-2 lg:gap-[18px] items-center justify-center bg-green px-[32px] py-[12px] lg:py-4 rounded-[128px] w-full lg:w-auto"
          >
            <span className="text-[13px] lg:text-[18px] font-krona text-white">
              Schedule a Quick Demo
            </span>
            <p className="bg-white rounded-full grid place-content-center p-[5.455px] lg:p-[7.273px]">
              <FaArrowRight className={`text-[20px] text-green`} />
            </p>
          </Link>
          <Link
            to={"/questions-library"}
            className="font-krona text-center text[14px] lg:text-[18px] text-green border-[3px] border-green px-[40px] py-[12px] lg:py-[20px] rounded-[128px] whitespace-nowrap"
          >
            Explore Assesst LIbrary
          </Link>
        </div>
      </div>
    </GradientSection>
  );
}

export default OnlineAssessmentMain;
