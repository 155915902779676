import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import AssessmentCard from "../../../ui/cards/assessmentCard";
import { Modal } from "../../../ui/wrappers";
import { assessmentData, assessmentModals } from "../../../data/assessmentData";
import "./style.module.css";
import { useNavigate } from "react-router-dom";

export default function Assessment() {
  const navigate = useNavigate();

  const [modelOpen, setModelOpen] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0); // Track active index
  const swiperRef = useRef(null); // Reference to Swiper instance

  const handleModelOpen = (type) => {
    const assessmentDetails = assessmentModals.find(
      (modal) => modal.type === type
    );
    if (assessmentDetails) {
      setSelectedAssessment(assessmentDetails);
      setModelOpen(true);
    } else {
      window.scrollTo(0, 1200);
      navigate(`/questions-library?type=${type}`);
    }
  };

  // Handle slide change
  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex); // Update active index on slide change
  };

  // Calculate the position of the indicator
  const indicatorPosition =
    ((activeIndex % assessmentData.length) / assessmentData.length) * 100;

  return (
    <>
      {modelOpen && (
        <Modal onClose={() => setModelOpen(false)}>
          {selectedAssessment && (
            <div className="flex flex-col max-w-[782px] max-h-[80vh] lg:max-h-[75vh] text-[#1E1B1E] bg-white h-full w-full p-4 lg:p-6 sm:rounded-[24px] overflow-auto scrollbar-hide">
              <h2 className="text-xl font-400 mb-6 font-krona">
                {selectedAssessment.title}
              </h2>
              <p className="mb-6 font-satoshi font-medium text-base">
                {selectedAssessment.description}
              </p>
              <h3 className="text-base font-bold mb-3 font-satoshi">
                {`Benefits of ${selectedAssessment.title}`}
              </h3>
              <ul className="list-disc pl-6 mb-6 text-base font-medium font-satoshi">
                {selectedAssessment.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
              <h3 className="text-base font-bold mb-3 font-satoshi">
                When to Use:
              </h3>
              <ul className="list-disc pl-6 mb-6 text-base font-medium font-satoshi">
                {selectedAssessment.whenToUse.map((usage, index) => (
                  <li key={index}>{usage}</li>
                ))}
              </ul>
              <p className="text-base font-medium font-satoshi leading-[22px]">
                {selectedAssessment.endLines}
              </p>
            </div>
          )}
        </Modal>
      )}

      <div className="flex flex-col items-center justify-center bg-[#7D4996]">
        <div className="wrapper flex flex-col gap-4">
          <h2 className="section_heading mt-[64px] md:mt-[120px] text-white ">
            Assessments for every need
          </h2>
          <h3 className="text-sm md:text-xl font-medium text-center font-satoshi text-white ">
            From technical skills to soft skills, explore assessments crafted to
            match diverse roles, industries, and hiring goals—ensuring the
            perfect fit for every position
          </h3>
        </div>
        <div className="pt-[56px] w-full overflow-hidden pb-[128px]">
          {/*  Mobile */}
          <div className="flex sm:hidden">
            <Swiper
              ref={swiperRef}
              spaceBetween={20} // Space between slides
              slidesPerView="auto" // Dynamic slide widths
              onSlideChange={handleSlideChange} // Update active index on slide change
              centeredSlides={true}
              freeMode={true} // Free mode enabled
              freeModeMomentum={false}
              loop={true}
              grabCursor={true} // Enable hand cursor on hover
            >
              {assessmentData.map((assessment, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "320px",
                    height: "auto",
                    flexShrink: 0,
                  }}
                >
                  <div
                    style={{
                      maxWidth: "320px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <AssessmentCard
                      title={assessment.title}
                      description={assessment.description}
                      buttonText={assessment.buttonText}
                      onClick={() => handleModelOpen(assessment.title)}
                      icon={assessment.icon}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* Desktop - Tablet */}
          <div className="hidden sm:flex swiper-container ">
            <Swiper
              ref={swiperRef}
              modules={[Autoplay]}
              spaceBetween={20} // Space between slides
              slidesPerView="auto" // Dynamic slide widths
              onSlideChange={handleSlideChange} // Update active index on slide change
              freeMode={true} // Free mode enabled
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              loop={true}
              speed={1000}
              grabCursor={true} // Enable hand cursor on hover
            >
              {assessmentData.map((assessment, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "320px",
                    height: "auto",
                    flexShrink: 0,
                  }}
                >
                  <div
                    style={{
                      maxWidth: "320px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <AssessmentCard
                      title={assessment.title}
                      description={assessment.description}
                      buttonText={assessment.buttonText}
                      onClick={() => handleModelOpen(assessment.title)}
                      icon={assessment.icon}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* Custom Progress Bar with Moving Indicator */}
          <div className="relative h-2 mt-8 md:hidden mx-8 sm:mx-52">
            <div
              className="progress-bar-background"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "8px",
                background: "#FFFFFF66", // Semi-transparent background
                borderRadius: "64px",
              }}
            ></div>
            {/* Moving Indicator */}
            <div
              className="progress-indicator"
              style={{
                position: "absolute",
                top: "0",
                left: `${indicatorPosition}%`, // Move indicator based on the progress
                width: "35px", // Width of the indicator
                height: "8px", // Height of the indicator
                background: "#FFFFFF", // Indicator color
                borderRadius: "64px", // Rounded corners
                transition: "left 0.3s ease", // Smooth transition
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
