import React, { useState } from "react";
import result from "../../../../asset/home/result/result.png";
import resultDesktop from "../../../../asset/home/result/result-desktop.png";
import resultTab from "../../../../asset/home/result/result-tab.png";
import resultMobile from "../../../../asset/home/result/result-mobile.png";
import { useNavigate } from "react-router-dom";

export default function Result() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/results");
    scrollToTop();
  };
  return (
    <>
      <div
        onClick={handleClick}
        className=" md:relative p-0 m-0 justify-between h-[322px] md:h-[364px] w-full md:w-[247px] rounded-[24px] cursor-pointer overflow-hidden md:transition-shadow duration-300 ease-in-out md:hover:shadow-[0px_8px_24px_0px_rgba(0,0,0,0.08)]"
      >
        <img
          src={resultDesktop}
          alt="Result Desktop Background"
          className="absolute inset-0 w-full h-full  object-cover  hidden md:flex"
        />
        <img
          src={resultTab}
          alt="Result Tab Background"
          className="absolute inset-0 w-full h-full hidden object-cover sm:flex md:hidden rounded-3xl"
        />
        <img
          src={resultMobile}
          alt="Result Mobile Background"
          className="absolute inset-0 w-full h-full  object-cover flex sm:hidden rounded-3xl"
        />
        <div className="relative z-10 p-6 flex flex-col justify-between md:justify-start h-full">
          <p className="font-satoshi font-900 text-lg text-[#CECECE] leading-[25px]">
            06
          </p>
          <div className="w-full sm:w-[330px]  md:w-full ">
            <p className="text-2xl text-[#1E1B1E] font-satoshi font-700 leading-[33px] py-2">
              Result Analytics
            </p>
            <div>
              <p className="text-base font-satoshi font-medium text-[#626062] leading-[22px]">
                Gain in-depth insights with candidate performance metrics,
                including hiring stats, assessment results, and module-wise
                breakdowns to set informed cut-offs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
