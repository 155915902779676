import React from "react";
import { GradientSection } from "../../ui/wrappers";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";

function TNIMain({ gradient, color }) {
  return (
    <GradientSection
      nav={true}
      color={color}
      bgColor={color}
      gradient={gradient}
      textColor={"text-green"}
    >
      <div className="flex flex-col relative">
        {/* Headings */}
        <h1 className="text-[24px] lg:text-[48px] font-krona tracking-[-0.05em]  max-w-[895px]">
          Training Needs Identification (TNI) by Ambition Hire
        </h1>
        <div className="mt-6">
          <div className="text-[14px] md:text-[22px] font-500 leading-[18.9px] md:leading-[34px] lg:leading-normal mt-4 lg:mt-6 text-[#505050]">
            <p>
              Identify, assess, and develop talent with Ambition Hire’s Training
              Needs Identification (TNI) tool, tailored to help companies make
              strategic talent decisions. From optimizing apprentice placements
              to guiding bonus and promotion approvals, TNI is designed to
              elevate internal assessments, ensuring candidates and employees
              align with company goals.
            </p>

            <p className="mt-5">
              Ambition Hire’s Training Needs Identification (TNI) empowers
              companies to evaluate employee capabilities and readiness,
              delivering actionable insights for placements, promotions, and
              development opportunities. Designed to support both apprentice
              hiring and performance evaluations, TNI provides customized
              assessments across various job functions and skill areas.
            </p>
          </div>
        </div>
        {/* Buttons */}
        <div className="mt-6 mb-12 lg:mb-24 lg:mt-[64px] flex flex-col   sm:flex-row gap-4 lg:gap-6">
          <Link
            to={"/quiz-demo"}
            className="flex gap-2 lg:gap-[18px] items-center justify-center bg-violet px-[32px] py-[12px] lg:py-4 rounded-[128px] w-full lg:w-auto"
          >
            <span className="text-[13px] lg:text-[18px] font-krona text-white">
              Schedule a Quick Demo
            </span>
            <p className="bg-white rounded-full grid place-content-center p-[5.455px] lg:p-[7.273px]">
              <FaArrowRight className={`text-[20px] text-violet`} />
            </p>
          </Link>
        </div>
      </div>
    </GradientSection>
  );
}

export default TNIMain;
