import { GradientBorder, GradientSection } from "../../ui/wrappers";
import { Icons } from "../../asset";
function EmployeesSurveySection3({ gradient, color }) {
  const features = [
    {
      title: "Tailored Insights for Actionable Decisions",
      text: " - Gather detailed feedback through surveys customized to your company’s specific goals. Analyze multiple factors critical to your workforce dynamics.",
    },
    {
      title: "Comprehensive Analytics",
      text: " - Receive clear, detailed reports to identify strengths, challenges, and opportunities for improvement within your organization.",
    },
    {
      title: "Anonymity for Honest Feedback:",
      text: " - Create a safe space for employees to share their thoughts candidly with our privacy-first, anonymous survey option.",
    },
    {
      title: "Boost Employee Engagement",
      text: " - Demonstrate that your organization values its employees by acting on their feedback and fostering an open, responsive workplace culture.",
    },
  ];
  const benefits = [
    "Identify factors affecting morale and implement solutions to foster a happier, more productive workforce.",
    "Recognize team strengths and areas for improvement to provide relevant training and development programs.",
    "Use recurring surveys to monitor progress and refine initiatives over time, ensuring sustained workplace growth.",
    "Access instant analytics, enabling you to act quickly on time-sensitive employee concerns or feedback.",
  ];

  return (
    <GradientSection sectionHeading={"Why Choose Employees’ Surveys?"}>
      <p className="my-8 text-center text-sm md:text-[22px] font-500 leading-[18.9px] md:leading-[34px] text-primary-light px-10">
        Employees’ Surveys from AmbitionHire ensure that your workforce has a
        voice, helping you shape strategies to create a more fulfilling and
        efficient work environment. By leveraging authentic feedback, you can
        drive initiatives that resonate with your team, resulting in a more
        aligned and motivated organization.
      </p>
      <div className="mt-8 sm:mt-[64px] lg:mt-24 flex flex-col gap-6 mb-32">
        {/* Features */}
        {features?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8"
            style={{ background: "#8CB56C0D" }}
          >
            <div className="text-base md:text-2xl leading-[21.6px] md:leading-[32.3px] text-primart-light font-700 flex gap-4">
              <div className="h-6 w-6 mt-2">
                <Icons.Asterisk color={color} />
              </div>

              <p>
                <span className="font-700">{item?.title}</span>{" "}
                <span className="font-500">{item?.text}</span>
              </p>
            </div>
          </div>
        ))}

        <h2 className="text-xl md:text-[32px] leading-[25px] md:leading-10 tracking-[-0.05em] text-center font-krona mt-24 md:mt-48 mb-12 md:mb-20">
          Key Benefits
        </h2>
        {benefits?.map((item, index) => (
          <div
            key={index}
            className="rounded-[16px] border py-6 px-8"
            style={{ background: "#3ABDB50D" }}
          >
            <div className="text-base md:text-2xl leading-[21.6px] md:leading-[32.3px] text-primart-light font-700 flex gap-4">
              <div className="h-6 w-6 ">
                <Icons.CircleCheck color={color} />
              </div>
              <p>{item}</p>
            </div>
          </div>
        ))}
      </div>
    </GradientSection>
  );
}

export default EmployeesSurveySection3;
