import React, { useState } from "react";
import { PrimaryGradientButton } from "../../../ui/buttons";
import { motion } from "framer-motion";

import Tests from "../../../asset/home/hire/tests.png";
import TestsMobile2 from "../../../asset/home/hire/tests-mobile-2.png";
import Customize from "../../../asset/home/hire/customize.png";
import Candidate from "../../../asset/home/hire/candidate.png";

function Hire() {
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  // Images corresponding to each index
  const images = [Tests, Customize, Candidate];
  const mobileImages = [TestsMobile2, Customize, Candidate];

  return (
    <div className="bg-[#FAFAFA] w-full ">
      <section className="wrapper">
        <h2 className="section_heading text-[#1E1B1E] mt-[64px] md:mt-[120px]">
          Why choose Ambition Hire?
        </h2>
        <h3 className="text-sm sm:text-base md:text-xl font-medium text-center mt-4 font-satoshi text-[#1E1B1E]">
          Empower your hiring process with Ambition Hire's advanced assessments,
          intuitive tools, and tailored features that streamline recruitment and
          help you identify top talent with precision
        </h3>
        <div className="flex md:hidden mt-16  justify-center h-[290px]">
          <div
            style={{
              width:
                selectedCardIndex === 0 || selectedCardIndex === 2
                  ? "361px"
                  : selectedCardIndex === 1
                  ? "434px"
                  : "551px",
              height: "100%",
            }}
          >
            <img
              src={mobileImages[selectedCardIndex]}
              alt={`Card ${selectedCardIndex}`}
              className="w-full h-full"
            />
          </div>
        </div>
        {/* Buttons Section */}
        <div className="flex flex-col gap-6 mt-14 w-full">
          <div className="flex flex-col md:flex-row justify-center items-center gap-4">
            <PrimaryGradientButton
              text="Choose from a wide range of 100+ tests"
              isSelected={selectedCardIndex === 0}
              onClick={() => setSelectedCardIndex(0)}
            />
            <PrimaryGradientButton
              text="Product customization options"
              isSelected={selectedCardIndex === 1}
              onClick={() => setSelectedCardIndex(1)}
            />
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center gap-4">
            <PrimaryGradientButton
              text="Enhanced candidate experience"
              isSelected={selectedCardIndex === 2}
              onClick={() => setSelectedCardIndex(2)}
            />
          </div>
        </div>

        {/* Image Display Section */}
        <div className="hidden md:flex mt-16  justify-center h-[290px]">
          <div
            style={{
              width:
                selectedCardIndex === 0
                  ? "664px"
                  : selectedCardIndex === 1
                  ? "434px"
                  : "551px",
              height: "100%",
            }}
          >
            <img
              src={images[selectedCardIndex]}
              alt={`Card ${selectedCardIndex}`}
              className="w-full h-full "
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hire;
